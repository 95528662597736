import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Push Jerk 4×6\\@70% 1RM`}</p>
    <p>{`Strict CTB Pullups 4×6`}</p>
    <p>{`then,`}</p>
    <p>{`“Jack”`}</p>
    <p>{`20:00 amrap of:`}</p>
    <p>{`10-Push Press (115/75)`}</p>
    <p>{`10-KBS’s (53/35)`}</p>
    <p>{`10-Box Jumps (24/20″)`}</p>
    <p><em parentName="p">{`*`}{`compare to 7/26/18`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      